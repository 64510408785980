import { Paper, styled } from "@mui/material";
import { BeautifulMentionsMenuProps } from "lexical-beautiful-mentions";
import { forwardRef } from "react";

// Styled wrapper for the mentions menu

const StyledMentionMenu = styled(Paper)(({ theme }) => ({
    zIndex: 100000,
    position: "absolute",
    ["ul"]: {
        margin: 0,
        padding: 0,
    },
}));

const CustomMentionList = forwardRef<HTMLUListElement, BeautifulMentionsMenuProps>(({ open, loading, ...props }, ref) => (
    <StyledMentionMenu>
        <ul {...props} ref={ref} />
    </StyledMentionMenu>
));

export default CustomMentionList;
