import { CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { $convertFromMarkdownString, $convertToMarkdownString, HIGHLIGHT, TRANSFORMERS } from "@lexical/markdown";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import ErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ClickAwayListener, Typography, styled } from "@mui/material";
import { UserDto, zMediumText } from "api-shared";
import classnames from "classnames";
import {
    $setSelection,
    BLUR_COMMAND,
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_LOW,
    EditorState,
    FOCUS_COMMAND,
    KEY_DOWN_COMMAND,
    LexicalEditor,
} from "lexical";
import { BeautifulMentionsItem, createBeautifulMentionNode } from "lexical-beautiful-mentions";
// Make sure to import ESM version of Plugin, otherwise it won't be able to access LexicalContext, which is imported as ESM
import { BeautifulMentionsPlugin } from "lexical-beautiful-mentions/BeautifulMentionsPlugin";
import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useAllUsers } from "../../domain/users";
import { CUSTOM_IMAGE_TRANSFORMER } from "./CustomImageTransformer";
import CustomMentionComponent from "./CustomMentionComponent";
import CustomMentionList from "./CustomMentionList";
import CustomMentionListItem from "./CustomMentionListItem";
import { CUSTOM_MENTION_TRANSFORMER } from "./CustomMentionTransformer";
import { CUSTOM_UNDERLINE_TRANSFORMER, LINE_BREAK_FIX } from "./CutomTransformers";
import { MarkdownEditorProps } from "./MarkdownEditor";
import { EmojiNode } from "./nodes/EmojiNode";
import { ImageNode } from "./nodes/ImageNode";
import DragDropPaste from "./plugins/DragDropPastePlugin";
import EmojiPickerPlugin from "./plugins/EmojiPickerPlugin";
import EmojisPlugin from "./plugins/EmojisPlugin";
import ImagesPlugin from "./plugins/ImagesPlugin";
import { MATCHERS } from "./utils/link-matchers";

const ToolbarPlugin = React.lazy(() => import("./plugins/ToolbarPlugin"));

const StyledPlaceholder = styled("div", { shouldForwardProp: (prop) => prop !== "isToolbarActive" })<{
    isToolbarActive: boolean;
}>(({ theme, isToolbarActive }) => ({
    color: theme.palette.text.disabled,
    overflow: "hidden",
    position: "absolute",
    textOverflow: "ellipsis",
    top: isToolbarActive ? theme.spacing(7.25) : theme.spacing(1),
    left: theme.spacing(2),
    right: theme.spacing(2),
    userSelect: "none",
    whiteSpace: "nowrap",
    display: "inline-block",
    pointerEvents: "none",
}));

const ContentEditableStyles = styled(ContentEditable, {
    shouldForwardProp: (prop) => prop !== "compact" && prop !== "fullHeight" && prop !== "disabled",
})<{
    compact: boolean;
    fullHeight: boolean;
    disabled: boolean;
}>(({ compact, fullHeight, disabled, theme }) => ({
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
    overflowWrap: "anywhere",
    height: "100%",
    overflowY: "auto",
    outline: "none",
    padding: theme.spacing(2),
    [`& p`]: {
        padding: 0,
        margin: 0,
        marginBottom: theme.spacing(0.5),
    },
    [`& p:last-of-type`]: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0),
    },
    [`& p:first-of-type`]: {
        marginBottom: theme.spacing(0.5),
        marginTop: theme.spacing(0),
    },
    [`& p:only-of-type`]: {
        margin: 0,
    },

    maxHeight: theme.spacing(24),
    minHeight: theme.spacing(18),
    ...(compact && {
        padding: theme.spacing(1, 2),
        minHeight: 0,
    }),
    ...(fullHeight && {
        maxHeight: 500,
        minHeight: 500,
    }),

    // Theme file will set this to the span and we can override those?!
    [".editor-text-strikethrough"]: {
        textDecoration: "line-through",
    },
    [".editor-text-underline"]: {
        textDecoration: "underline",
    },
    [".editor-text-underlineStrikethrough"]: {
        // this won't work in markdown
        textDecoration: "underline line-through",
    },
}));

// Keep in sync with plugins below!
export const EDITOR_NODES = [
    ImageNode,
    HeadingNode,
    LinkNode,
    AutoLinkNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    EmojiNode,
    ...createBeautifulMentionNode(CustomMentionComponent),
];

const MARKDOWN_TRANSFORMERS = [
    CUSTOM_IMAGE_TRANSFORMER,
    LINE_BREAK_FIX,
    CUSTOM_MENTION_TRANSFORMER,
    CUSTOM_UNDERLINE_TRANSFORMER,
    ...TRANSFORMERS.filter((t) => t !== HIGHLIGHT), // HIGHLIGHT clashes with our image transformer
];

// We need to use the query mechanism here to allow mapping user input to our @uid:1234 format
// otherwise the plugin would just search lexically for the string
const queryMentions = async (
    queryString: string | null | undefined,
    mentionItems: {
        [key: string]: string | number | boolean;
        value: string;
    }[],
    users: UserDto[],
): Promise<BeautifulMentionsItem[]> => {
    if (queryString == undefined) {
        return [];
    }

    const lowerQuery = queryString.toLowerCase();
    const filteredUsers = users.filter(
        (user) => user.displayname?.toLowerCase().includes(lowerQuery) ?? user.realname?.toLowerCase().includes(lowerQuery),
    );

    return mentionItems.filter((item) => {
        return filteredUsers.find((u) => u.id === +item.id && item.isAllowedUser);
    });
};

function validateMediumText(value: unknown) {
    return zMediumText.safeParse(value).success;
}

function setEditorState(value?: string | null) {
    // https://github.com/facebook/lexical/issues/2636
    // Empty string is covered below to allow for ctrl+x/delete value update
    if (value == null) {
        $setSelection(null); // Don't autofocus
        return;
    }

    $convertFromMarkdownString(value, MARKDOWN_TRANSFORMERS);
    $setSelection(null); // Don't autofocus
}

function MarkdownLexicalEditor({
    value,
    disabled,
    updateValue,
    mentionAllUsers,
    mentionActiveUsers,
    className,
    onChange,
    onKeyDown,
    placeholder,
    hideToolbar,
    compact,
    fullHeight,
    validateValue,
    onHasValidationError,
}: Readonly<MarkdownEditorProps>) {
    const [editor] = useLexicalComposerContext();
    const [, setFocus] = useState(false);

    // This is a kind of hopeful status to check if the user still edits data as there seems to be a need to hide the toolbar
    // This might be unset on events like clicks outside of the editor
    const [hasUserIsInteracting, setUserIsInteracting] = useState(false);

    const allUsers = useAllUsers();

    // Create a combined lookup for all users that are resolvable and the ones that are selectable
    const mentionItems = useMemo(
        () => ({
            "@":
                mentionAllUsers != undefined
                    ? mentionAllUsers.map((id) => ({
                          value: `uid:${String(id)}`,
                          id,
                          isAllowedUser: mentionActiveUsers != undefined ? mentionActiveUsers.includes(id) : true,
                      }))
                    : [],
        }),
        [mentionActiveUsers, mentionAllUsers],
    );

    const handleSearch = useCallback(
        (trigger: string, queryString?: string | null) => queryMentions(queryString, mentionItems["@"], allUsers),
        [mentionItems, allUsers],
    );

    const validateValueOnChange = (newValue: unknown) => {
        const validateFunction = validateValue ?? validateMediumText;
        return validateFunction(newValue);
    };

    const [markdownText, setMarkdownText] = useState(value);
    function onChangeEditorContent(editorState: EditorState, lexicalEditor: LexicalEditor, tags: Set<string>) {
        editorState.read(() => {
            // See Markdown CustomTransformer for more info - Keep empty paragraphs with first regex - Remove last line break
            const markdown = $convertToMarkdownString(MARKDOWN_TRANSFORMERS).replaceAll(/\n{4}/gm, "\n\n  \n\n").replaceAll(/\n\n$/g, "");
            const hasValidationError = !validateValueOnChange(markdown);
            onHasValidationError?.(hasValidationError);

            // Markdown will always return empty string for empty editor but initial value can be undefined or null
            if (((value !== undefined && markdown !== "") || value !== markdown) && !hasValidationError) {
                const valueChanged = value !== markdown;
                setMarkdownText(markdown);

                if (onChange !== undefined && valueChanged) {
                    onChange(markdown);
                }
            }
        });
    }

    useEffect(() => {
        // BEWARE: This must always be written in a way to not cause an infinite loop with onChange!
        if (!hasUserIsInteracting) {
            // Don't update the editor if the user is interacting with it (blur will overwrite the value)
            editor.update(() => {
                setEditorState(value);
            });
        }
    }, [value, editor, hasUserIsInteracting]);

    useEffect(() => {
        editor.setEditable(!disabled);
    }, [editor, disabled]);

    useEffect(() => {
        return editor.registerCommand(
            BLUR_COMMAND,
            () => {
                setFocus(false);
                if (updateValue !== undefined && value !== markdownText) {
                    updateValue(markdownText ?? "");
                }
                return false;
            },
            COMMAND_PRIORITY_EDITOR,
        );
    }, [editor, markdownText, updateValue, value]);

    useEffect(
        () =>
            editor.registerCommand(
                FOCUS_COMMAND,
                () => {
                    setUserIsInteracting(true);
                    setFocus(true);
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        [editor],
    );

    useEffect(() => {
        return editor.registerCommand(
            KEY_DOWN_COMMAND,
            (payload) => {
                if (onKeyDown !== undefined) {
                    onKeyDown(payload);
                }
                return false;
            },
            COMMAND_PRIORITY_EDITOR,
        );
    }, [editor, markdownText, onKeyDown]);

    const handleClickAway = () => {
        setUserIsInteracting(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Typography component="div" className={classnames(className, {})}>
                {!hideToolbar && hasUserIsInteracting ? (
                    <Suspense fallback={<></>}>
                        <ToolbarPlugin />
                    </Suspense>
                ) : (
                    <></>
                )}
                <RichTextPlugin
                    contentEditable={
                        <ContentEditableStyles compact={compact ?? false} fullHeight={fullHeight ?? false} disabled={disabled ?? false} />
                    }
                    placeholder={
                        <StyledPlaceholder isToolbarActive={!hideToolbar && hasUserIsInteracting}>{placeholder}</StyledPlaceholder>
                    }
                    ErrorBoundary={ErrorBoundary}
                />
                <ListPlugin />
                <LinkPlugin />
                <EmojisPlugin />
                <EmojiPickerPlugin />
                <AutoLinkPlugin matchers={MATCHERS} />
                <BeautifulMentionsPlugin
                    triggers={["@"]}
                    menuComponent={CustomMentionList}
                    menuItemComponent={CustomMentionListItem}
                    onSearch={handleSearch}
                />
                <OnChangePlugin onChange={onChangeEditorContent} />
                <MarkdownShortcutPlugin transformers={MARKDOWN_TRANSFORMERS} />
                <DragDropPaste />
                <ImagesPlugin />
            </Typography>
        </ClickAwayListener>
    );
}

export default styled(MarkdownLexicalEditor)(({ theme }) => ({
    borderRadius: "inherit",
    padding: 0,
    margin: 0,
    position: "relative",
}));
