import { MenuItem } from "@mui/material";
import { BeautifulMentionsMenuItemProps } from "lexical-beautiful-mentions";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useAllUsers } from "../../domain/users";
import { formatUserFromId } from "../../lib/formatters";

// Styled wrapper for the mentions menu

const CustomMentionListItem = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(
    ({ selected, item, id, isAllowedUser, ...props }, ref) => {
        const { t: translate } = useTranslation();

        const allUsers = useAllUsers();
        const userName = formatUserFromId(id, allUsers, { translate });

        return (
            <MenuItem {...props} ref={ref} selected={selected} dense>
                {userName}
            </MenuItem>
        );
    },
);

export default CustomMentionListItem;
