import type { TextMatchTransformer } from "@lexical/markdown";
import { TextNode } from "lexical";
import { CustomBeautifulMentionNode } from "lexical-beautiful-mentions";

// We need to parse our custom mention syntax to a node
// The mention-plugin will only find @text nodes in rich text not our markdown
export const CUSTOM_MENTION_TRANSFORMER: TextMatchTransformer = {
    dependencies: [TextNode],
    // Used on convert node to markdown
    // We don't need this as VD will use the @uid:xxx syntax
    export: (node, exportChildren) => {
        return null;
    },
    trigger: "@",
    regExp: /@(uid:(\d+))/, // @uid:123
    importRegExp: /@(uid:(\d+))/, // @uid:123
    // Used to convert text to node
    replace: (parentNode, match) => {
        // value: uid:123 - id: 123 - api: @uid:123
        const mentionNode = new CustomBeautifulMentionNode("@", match[1], { id: +match[2], api: match[0] });
        parentNode.replace(mentionNode);
    },
    type: "text-match",
};
