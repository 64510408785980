import type { ElementTransformer, TextMatchTransformer } from "@lexical/markdown";
import { $createParagraphNode, $isTextNode, ParagraphNode, TextNode } from "lexical";

/*
Underline is not supported by markdown https://github.com/facebook/lexical/discussions/3519
We added the support for the old quill editor.
This is a custom transformer to support underline.
https://github.com/facebook/lexical/issues/2715
*/
export const CUSTOM_UNDERLINE_TRANSFORMER: TextMatchTransformer = {
    dependencies: [TextNode],
    export: (node, _) => {
        if ($isTextNode(node) && typeof node.hasFormat === "function" && node.hasFormat("underline")) {
            return "++" + node.getTextContent() + "++";
        }
        return null;
    },
    importRegExp: /\+\+.*?\+\+/,
    regExp: /\+\+.*?\+\+/,
    replace: (textNode, _) => {
        textNode.setTextContent(textNode.getTextContent().replace(/\+\+/g, ""));
        textNode.setFormat("underline");
    },
    trigger: "+",
    type: "text-match",
};

/*
https://github.com/facebook/lexical/issues/4271 with the line break regex in the convertToMarkdownString

Paste as Plaintext!
-INPUT-----------------------------------
P1
P3

P4 after Empty

P6 after Empty

-----------------------------------------

QUILL + Fix
'P1\n\nP3\n\n  \n\nP4 after Empty\n\n  \n\nP6 after Empty'

LEXICAL + Fix
"P1\n\nP3\n\n  \n\nP4 after Empty\n\n  \n\nP6 after Empty"
*/
export const LINE_BREAK_FIX: ElementTransformer = {
    dependencies: [ParagraphNode],
    export: (node) => {
        return null;
    },
    regExp: /^\s\s$/,
    replace: (textNode, nodes, _, isImport) => {
        if (isImport && nodes.length === 1) {
            nodes[0].replace($createParagraphNode());
        }
    },
    type: "element",
};
