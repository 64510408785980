import { LexicalComposer } from "@lexical/react/LexicalComposer";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import { FormHelperText, IconButton, Stack, alpha, styled } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { reportError } from "../../infrastructure/sentry";
import { translationKeys } from "../../translations/main-translations";
import Alert from "../Alert";
import MarkdownLexicalEditor, { EDITOR_NODES } from "./MarkdownLexicalEditor";
import lexicalEditorTheme from "./utils/lexicalEditorTheme";

// This is the wrapper for the composer. Try to keep this file as lean as possible. We basically pass all props to the composer.

const Container = styled("div", { shouldForwardProp: (name) => name !== "hasError" })<{ hasError: boolean }>(({ theme, hasError }) => ({
    height: "inherit",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: hasError ? alpha(theme.palette.error.light, 0.08) : theme.palette.background.light,
    border: `1px solid ${hasError ? theme.palette.error.main : theme.palette.divider}`,
}));

export interface MarkdownEditorProps {
    mentionAllUsers?: number[];
    mentionActiveUsers?: number[];
    onKeyDown?: (e: KeyboardEvent) => void;
    value?: string | null;
    updateValue?: (newValue: string) => void;
    disabled?: boolean;
    fullHeight?: boolean;
    onChange?: (newValue: string) => void;
    hideToolbar?: boolean;
    className?: string;
    placeholder?: string;
    compact?: boolean;
    /** Default validation is on validateMediumText */
    validateValue?: (newValue: unknown) => boolean;
    onHasValidationError?: (hasError: boolean) => void;
}

export default function MarkdownEditor({ ...editorProps }: Readonly<Omit<MarkdownEditorProps, "onHasValidationError">>) {
    const { t: translate } = useTranslation();
    const [hasError, setHasError] = useState(false);

    const { disabled, className } = editorProps;
    const initialConfig = {
        namespace: "VdMdEditor",
        nodes: EDITOR_NODES,
        theme: lexicalEditorTheme,
        onError: (error: Error) => {
            // Catch any errors that occur during Lexical updates and log them to sentry
            // Lexical will try to recover gracefully without losing user data if the error is not thrown.
            reportError(error);
            setHasError(true);
        },
        editable: !disabled,
        // editorState - We let the editor manage this to allow for external value changes
    };

    const [hasValidationError, setHasValidationError] = React.useState(false);

    if (hasError) {
        return (
            <Stack direction="row" spacing={1} justifyContent="space-between">
                <Alert dense severity="warning">
                    {translate(translationKeys.VDLANG_MARKDOWN_EDITOR_ERROR_INFO)}
                </Alert>
                <IconButton
                    sx={{ alignSelf: "center" }}
                    onClick={() => {
                        setHasError(false);
                    }}
                >
                    <CachedRoundedIcon />
                </IconButton>
            </Stack>
        );
    }

    return (
        <Container className={className} hasError={hasValidationError}>
            <LexicalComposer initialConfig={initialConfig}>
                <MarkdownLexicalEditor {...editorProps} onHasValidationError={setHasValidationError} />
            </LexicalComposer>

            {hasValidationError ? (
                <FormHelperText error sx={{ ml: 0.5 }}>
                    {translate(translationKeys.VDLANG_MARKDOWN_EDITOR_VALUE_TOO_LONG)}
                </FormHelperText>
            ) : null}
        </Container>
    );
}
