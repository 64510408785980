import { TextMatchTransformer } from "@lexical/markdown";
import { $createImageNode, $isImageNode, ImageNode } from "./nodes/ImageNode";

export const CUSTOM_IMAGE_TRANSFORMER: TextMatchTransformer = {
    dependencies: [ImageNode],
    export: (node, exportChildren) => {
        if (!$isImageNode(node)) {
            return null;
        }

        return "![" + node.getAltText() + "](" + node.getSrc() + ")";
    },
    trigger: "!",
    importRegExp: /!\[(?<alt>.*?)\]\((?<data>.+?)\)/,
    regExp: /!\[(?<alt>.*?)\]\((?<data>.+?)\)/,
    replace: (parentNode, match) => {
        if (match.groups === undefined) {
            return;
        }
        const mentionNode = $createImageNode({ src: match.groups.data, altText: match.groups.alt });
        parentNode.replace(mentionNode);
    },
    type: "text-match",
};
