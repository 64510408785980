import { Chip, avatarClasses, styled } from "@mui/material";
import { BeautifulMentionComponentProps } from "lexical-beautiful-mentions";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useAllUsers } from "../../domain/users";
import { formatUserFromId } from "../../lib/formatters";
import UserAvatar from "../user/UserAvatar";
import UserEntryWithPopup from "../user/UserEntryWithPopup";

// Allows for showing the user avatar inside the editor

const MentionsChip = styled("span")({
    maxWidth: "100%", // Ellipsize mention chips when too little space is available
});

const Avatar = styled(UserAvatar)(({ theme }) => ({
    [`&.${avatarClasses.root}`]: {
        color: theme.palette.background.default, // Chip overwrites avatar default text color, restore
    },
}));

const CustomMentionComponent = forwardRef<HTMLDivElement, BeautifulMentionComponentProps<{ id: number }>>(
    ({ trigger, value, children, data, ...other }, ref) => {
        const users = useAllUsers();
        const { t: translate } = useTranslation();

        const user = users.find((u) => u.id === data?.id);

        return (
            <UserEntryWithPopup
                user={user}
                triggerElement={
                    <Chip
                        avatar={<Avatar user={user} component="span" />}
                        label={user ? formatUserFromId(user.id, users, { translate }) : ""}
                        component={MentionsChip}
                        size="small"
                    />
                }
            />
        );
    },
);

export default CustomMentionComponent;
